import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import TopMenu from '../components/TopMenu';
import BottomMenu from '../components/BottomMenu';
class Contacts extends Component {
    render() {
        return (
                        <>
                <TopMenu /><main className="contacts">
                <Helmet title='Contatta Lericas - San Lorenzo a Merse, Siena, Toscana'>
                    <meta
                        name="description"
                        content="Lericas si trova a San Lorenzo a Merse, Siena, Toscana - Vieni a scoprire i nostri prodotti!"
                    />
                </Helmet>
                <div className="main-container">
                    <section className="title">
                        <h1>Lericas</h1>
                        <h3>di Strazza Luigi</h3>
                    </section>
                    <article className="description">
                        <section className="description-item">
                            <span>Contattaci</span>
                            <span>
                                Email: <a href="mailto:posta@lericas.it">posta@lericas.it</a>
                                <br />Cell: <a href="tel:+393385677719">+39 338 5677719</a>
                                <br />Partita Iva: 009800605299
							</span>
                        </section>
                        <section className="description-item">
                            <span>Vieni a trovarci</span>
                            <span>
                                Strada Provinciale 32<br />San Lorenzo a Merse<br />Monticiano
								(SI) - 53015
							</span>
                        </section>
                    </article>
                </div>
                <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2634.7320832998835!2d11.272275631540479!3d43.147959472436476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13298596dcd543eb%3A0x3ec9adfaedc65ab3!2sLericas+Di+Strazza+Luigi!5e1!3m2!1sit!2sit!4v1537017475816"
                    width="100%"
                    height="600"
                    frameBorder="0"
                    style={{ border: 'none' }}
                    allowFullScreen
                />
            </main><BottomMenu />
            </>
        );
    }
}

export default Contacts;
